<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('update:activated', false)"
    @keydown="$emit('update:activated', false)"
    max-width="900"
    scrollable
  >
    <v-card>
      <v-card-title class="headline">Diferenças de Flexibilidade:</v-card-title>
      <v-card-text>
        <b>Flexível</b>: A conciliação irá funcionar da forma padrão, permitindo
        que itens na <i>Partida</i> sejam conciliados com itens na
        <i>Referência</i>, mesmo que as quantidades sejam inferiores as
        quantidades dos itens da <i>Partida</i>.
      </v-card-text>
      <v-card-text>
        <b>Rígida</b>: A conciliação não irá conciliar o mesmo item da
        <i>Partida</i> com mais de um item da <i>Referência</i>.
      </v-card-text>
      <v-card-text>
        <b>Parcial</b>: A conciliação irá apenas conciliar itens da
        <i>Partida</i> caso as quantidades dos itens na <i>Referência</i> sejam
        iguais ou superiores as quantidades da <i>Partida</i>.
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="$emit('update:activated', false)"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    activated: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    dialog() {
      return this.activated;
    }
  }
};
</script>
